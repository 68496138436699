<template>
  <Layout>
    <div class="row">
      <div class="col-12 justify-content-end">
        <div class="text-end align-items-end mt-3 mb-4">
          <a href="/create-bot" class="btn btn-success">{{ $t("t-create-bot") }}</a>
        </div>
        <div class="row row-cols-xxl-3 row-cols-lg-2 row-cols-1">
          <div class="col" v-for="(bot, index) of bots" :key="index">
            <div class="card card-body">
              <div class="d-flex mb-2 align-items-center">
                <div class="flex-shrink-0">
                  <div class="avatar-sm rounded-circle" style="background: #405189; text-align: center; line-height: 44px; font-size: 25px;">
                    <i class="mdi mdi-robot" style="color: white;"></i>
                  </div>
                </div>
                <div class="flex-grow-1 ms-2">
                  <h5 class="card-title mb-1">
                      {{ bot.title }}
                  </h5>
                  <p class="text-muted mb-0">{{ $t("t-" + bot.exchange_connection.type) }}</p>
                </div>
                <div class="flex-shrink-0">
                  <a href="#" style="font-size: 2rem;" data-bs-toggle="modal" data-bs-target="#update-bot" @click="openEditForm(bot._id)">
                    <i class="ri ri-edit-box-line text-warning" style="vertical-align: bottom; font-weight: bold;" ></i>
                  </a>
                </div>
              </div>

              <div class="d-flex justify-content-between border-bottom border-bottom-dashed py-2">
                <p class="fw-medium mb-0">
                  {{ $t("t-status") }}
                </p>
                <span class="badge "
                      :class="{'badge-soft-danger': !bot.enabled, 'badge-soft-success': bot.enabled}">
                  {{ bot.enabled ? $t("t-status-active") : $t("t-status-disable") }}
                </span>

                <div class="form-check form-switch form-switch-success">
                  <input class="form-check-input" @change="toggleBot(bot)" type="checkbox" role="switch"
                         id="SwitchCheck11"
                         :checked="bot.enabled">
                  <label class="form-check-label" for="SwitchCheck11"></label>
                </div>
              </div>
              <div class="d-flex justify-content-between border-bottom border-bottom-dashed py-2">
                <p class="fw-medium mb-0">
                  {{ $t("t-bot-base-asset") }}
                </p>
                <span>
                  {{bot.base_currency}}
                </span>
              </div>
              <div class="d-flex justify-content-between border-bottom border-bottom-dashed py-2">
                <p class="fw-medium mb-0">
                  {{ $t("t-creation-date") }}
                </p>
                <span>
                  {{ bot.created_date }} {{ bot.created_time }}
                </span>
              </div>
              <div class="mt-auto ">
                <div class="d-flex justify-content-between py-2">
                  <div class="flex-grow-1">
                    <div class="fw-medium mb-0">{{ $t("t-bot-active-deals") }}</div>
                  </div>
                  <div class="flex-shrink-0">
                    <div><i class="ri-list-check align-bottom me-1 text-muted"></i> {{ bot.active_deals }} / {{bot.max_active_deals}}</div>
                  </div>
                </div>
                <div class="progress progress-sm animated-progess border-bottom border-bottom-dashed">
                  <div class="progress-bar bg-success" role="progressbar"
                       :style="{width: (bot.active_deals / bot.max_active_deals * 100)+'%'}"></div>
                </div>
              </div>

              <!--                      <a href="javascript:void(0)" class="btn btn-primary btn-sm mt-4">See Details</a>-->
            </div>
          </div><!-- end col -->
        </div><!-- end row -->
      </div><!-- end col -->
    </div>
    <update-bot-form-modal ref="updateBotForm"  ></update-bot-form-modal>

  </Layout>
</template>
<style>
.ribbon-three::after {
  border-left: 64px solid transparent;
  border-right: 64px solid transparent;
}

.ribbon-three > span {
  width: 130px;
}
</style>
<script>
import Layout from "./../layouts/main";
import Swal from "sweetalert2";

import Bot from "@/state/entities/bot";
import UpdateBotFormModal from "@/pages/update-bot-form-modal";
import ExchangeConnection from "@/state/entities/exchangeConnection";

export default {
  data() {
    return {
      predefine_strategy: 1,
      rows: [],
    };
  },
  components: {
    UpdateBotFormModal,
    Layout,
  },
  methods: {
    openEditForm(bot_id) {
    this.$refs.updateBotForm.initialize(bot_id)
    },
    enable(bot) {
      Bot.api().enable(bot._id).then(() => {
        Swal.fire(this.$t("t-bot-successfully-enabled"), this.$t("t-bot-enabled-note-message"), "success");
      }).catch((err) => {
        if (err.response && err.response.data) {
          if (err.response.data.message === "Subscription expired") {
            Swal.fire("Subscription is inactive.", this.$t("t_inactive_subscription_message"), "warning").then(() => {
              /** @TODO hack: can't prevent toggle changes. status not changed but on UI changes affected  */
              window.location.reload()
            });
          }
          if (err.response.data.message === "Limit active bots") {
            Swal.fire("Active bots limited.", this.$t("t_bots_limit_reached_message"), "warning").then(() => {
              /** @TODO hack: can't prevent toggle changes. status not changed but on UI changes affected  */
              window.location.reload()
            });
          }
        } else {
          Swal.fire("System error.", "Something went wrong.", "warning").then(() => {
            /** @TODO hack: can't prevent toggle changes. status not changed but on UI changes affected */
            window.location.reload()
          });
        }
      })
    },
    disable(bot) {
      Bot.api().disable(bot._id)
    },
    toggleBot(bot) {
      if (bot.enabled === true) {
        this.disable(bot);
      } else {
        this.enable(bot);
      }
    },
  },
  computed: {
    bots() {
      return Bot.query().with(["exchange_connection"]).orderBy('created_at', 'desc').all().filter(i=>i.archived===false);
      // return Bot.all().reverse();
    },
  },
  async beforeMount() {
    (await ExchangeConnection.api().get('/exchange-connection'));
    (await Bot.api().get('/bot'));
  },
};
</script>
