<template>
  <b-modal v-model="openModal"
           hide-footer
           id="update-bot"
           class="modal fade"
           role="dialog"
           :title='$t("t-edit-bot")'>
  <form v-on:submit.prevent="submit">
    <div class="row g-3">
      <div class="col-xxl-12">
        <div>
          <label for="name" class="form-label">{{ $t('t-bot-bot-name') }}</label>
          <input required type="text" class="form-control" id="name" :placeholder='$t("t-example") + " My trading bot John"' v-model="form.title"/>
        </div>
      </div>
<!--      <div class="col-xxl-12">-->
<!--        <label for="api_key_id" class="form-label">{{ $t('t-api-key-title') }}</label>-->
<!--        <select-->
<!--            disabled-->
<!--            class="form-select mb-3"-->
<!--            aria-label="Default select example"-->
<!--            v-model="form.exchange_connection_id"-->
<!--        >-->
<!--          <option value="">{{ $t('t-bot-link-your-api-key') }}</option>-->
<!--          <option v-for="(credential) of credentials" :key="credential.$id" :value="credential.$id">{{credential.title}}</option>-->
<!--        </select>-->
<!--      </div>-->

      <div class="col-xxl-12">
        <div>
          <label for="first_order_amount" class="form-label">{{ $t('t-bot-first-order-amount') }} {{baseCurrency}}</label>
          <input type="number" required class="form-control" min="20" id="first_order_amount" :placeholder='$t("t-example") + " 20"' v-model="form.first_order_amount" />
          <div class="form-text">
            {{ $t('t-minimum') }}: 20
          </div>
        </div>
      </div>
      <div class="col-xxl-12">
        <div>
          <label for="take-profit" class="form-label">{{ $t('t-bot-active-deals') }}</label>
          <input type="number" required class="form-control" id="take-profit" max="13" :placeholder='$t("t-example") + " 5"' v-model="form.active_deals" />
        </div>
      </div>
      <div class="col-xxl-12">
        <div>
          <label for="take-profit" class="form-label">{{ $t('t-bot-required-balance') }} {{baseCurrency}}</label>
          <input type="text" disabled class="form-control" id="take-profit" :value="requiredBalance" />
        </div>
      </div>
      <div class="col-xxl-12">
        <div>
          <label class="form-label">{{ $t('t-bot-blacklisted-pair') }}</label>
          <Multiselect
              mode="tags"
              :searchable="true"
              :create-option="true"
              v-model="form.blacklisted_pairs"
              :options="form.blacklisted_pairs"
          />
        </div>
      </div>

      <!--end col-->
      <div class="col-lg-6">
        <div class="hstack gap-1 justify-content-start">
          <button type="button" class="btn btn-danger" @click="confirm_delete">{{ $t('t-delete-button') }}</button>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="hstack gap-2 justify-content-end">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">{{ $t('t-close-button') }}</button>
          <button type="submit" class="btn btn-primary">{{ $t('t-update-button') }}</button>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </form>
  </b-modal>
</template>

<script>
import ExchangeConnection from "@/state/entities/exchangeConnection";
import BotService from "@/services/bot-service";
import Bot from "@/state/entities/bot";
import Swal from "sweetalert2";
import Multiselect from "@vueform/multiselect";

export default {
  data() {
    return {
      openModal: false,
      baseCurrency: null,
      botId: null,
      preset: null,
      form: {
        title: '',
        exchange_connection_id: '',
        first_order_amount: '',
        active_deals: '',
        blacklisted_pairs: [],
      }
    }
  },
  computed: {
    requiredBalance() {
      if(!this.form.active_deals || !this.form.first_order_amount){
        return "";
      }

      if(this.preset === 'conservative') {
        return this.form.active_deals * this.form.first_order_amount * (5+1);
      }
      if(this.preset === 'moderate') {
        return this.form.active_deals * this.form.first_order_amount * (4+1);
      }
      if(this.preset === 'aggressive') {
        return this.form.active_deals * this.form.first_order_amount;
      }
      return 'empty';
    },
    credentials() {
      return ExchangeConnection.all().filter((credential) => {
        if(this.preset === 'moderate' || this.preset === 'conservative') {
          return credential.type === "binance-spot"
        }
        if(this.predefine_strategy === 'aggressive') {
          return credential.type === "binance-futures" || credential.type === "bitget-futures"
        }
      });
    },
  },
  components: {
    Multiselect
  },
  async mounted() {
    /** @TODO call via dispatch store */
    (await ExchangeConnection.api().get('/exchange-connection'));
  },
  methods: {
    archive_bot(botId) {
      Bot.api().archive(botId)
    },
    confirm_delete() {
      Swal.fire({
        title: this.$t("t-delete-confirm-question"),
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t("t-delete-confirm"),
        cancelButtonText: this.$t("t-cancel-button"),
      }).then((result) => {
        if (this.bot.active_deals > 0) {
          Swal.fire(this.$t("t-delete-failed"), this.$t("t-bot-delete-failed-active-deals"), "warning");
          return;
        }
        if (this.bot.enabled === true) {
          Swal.fire(this.$t("t-delete-failed"), this.$t("t-bot-delete-failed-enabled"), "warning");
          return;
        }
        if (result.value) {
          this.archive_bot(this.botId);
          this.openModal = false;
          Swal.fire(this.$t("t-delete-deleted"), this.$t("t-bot-delete-success-message"), "success");
        }
      });
    },

    async initialize(bot_id) {
      const botResponse = await BotService.findOne(bot_id);
      this.form.title = botResponse.data.title
      this.form.exchange_connection_id = botResponse.data.exchange_connection_id
      this.form.first_order_amount = botResponse.data.first_order_amount
      this.form.active_deals = botResponse.data.max_active_deals
      this.form.blacklisted_pairs = botResponse.data.blacklisted_pairs
      this.preset = botResponse.data.preset
      this.baseCurrency = botResponse.data.base_currency
      this.botId = bot_id
      this.bot = botResponse.data;
    },
    submit() {
      BotService.update(this.botId, this.form.title, this.form.first_order_amount, this.form.active_deals, this.form.first_order_amount, this.form.blacklisted_pairs).then((response) => {
        this.openModal = false;
        if(response.status === 200) {
          (Bot.api().get('/bot'));
          Swal.fire({
            title: "Bot successfully updated.",
            icon: 'success',
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          /** @TODO: error handling.Add message from server */
          Swal.fire({
            title: this.$t("t-default-error-response-message"),
            icon: 'error',
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }).catch((er) => {
        this.openModal = false;
        console.log(er);
        /** @TODO: error handling.Add message from server */
        Swal.fire({
          title: this.$t("t-default-error-response-message"),
          icon: 'error',
          timer: 3000,
          timerProgressBar: true,
        });
      });
    },
  },
};
</script>
